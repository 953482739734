import { useContext, useEffect } from "react";
import useApi from "../../hooks/useApi";
import ProjectsContext from "../../store/contexts/ProjectsContext/ProjectsContext";
import WorkStyle from "./Work.styled";

const Work = (): JSX.Element => {
  const { projects } = useContext(ProjectsContext);

  const orderedProjects = projects.sort(
    (a, b) => parseInt(a.orden) - parseInt(b.orden)
  );

  const { loadProjects } = useApi();

  useEffect(() => {
    loadProjects();
  }, [loadProjects]);

  return (
    <WorkStyle className="work">
      <ul className="work__list">
        {orderedProjects.map(({ frontBackup, nombre, id }) => (
          <li className="work__item" key={id}>
            <a href={`/work/${nombre}`}>
              <div className="work__information">
                <span className="work__title">{nombre}</span>
              </div>
              <img
                src={`${frontBackup}`}
                alt="img"
                width={300}
                height={150}
              ></img>
            </a>
          </li>
        ))}
      </ul>
    </WorkStyle>
  );
};

export default Work;
