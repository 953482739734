const addSpaces = (str: string): string => {
  let result: string = str.charAt(0);

  for (let i: number = 1; i < str.length; i++) {
    if (
      str.charAt(i) === str.charAt(i).toUpperCase() &&
      str.charAt(i - 1) !== str.charAt(i - 1).toUpperCase()
    ) {
      result += " ";
    }
    result += str.charAt(i);
  }

  return result;
};

export default addSpaces;
