import styled from "styled-components";

const ContactStyle = styled.section`
  color: white;
  width: 100vw;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  .contact {
    &__email {
      @media (min-width: 900px) {
        font-size: 3rem;
      }
      font-size: 1.2rem;
      text-decoration: underline;
      margin: 0;
      color: white;
    }
    &__mobile {
      @media (min-width: 900px) {
        font-size: 2rem;
      }
      font-size: 1.2rem;
      color: white;
      text-decoration: none;
    }
  }
`;

export default ContactStyle;
