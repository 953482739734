import styled from "styled-components";

const ProjectStyle = styled.article`
  background-color: white;
  padding: 32vh 50px 50px 50px;
  @media (min-width: 1100px) {
    padding: 35vh 10rem 10rem 10rem;
  }
  min-height: 100vh;
  .project {
    &__front {
      position: absolute;
      top: 0;
      right: 0;
      height: 45vh;
      width: 100vw;
      &-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    &__title {
      font-weight: bold;
      font-size: 3rem;
      margin: 0;
    }
    &__name {
      font-weight: bold;
      font-size: 2rem;
    }
    &__description {
      font-weight: bold;
      font-size: 1.2rem;
      color: #545252;
    }
    &__credits {
      color: #545252;
      list-style: none;
      margin: 0;
      padding: 0;
      font-weight: bold;
      font-size: 1.2rem;
      & :nth-child(2) {
        margin-bottom: 10px;
      }
    }
    &__video-container {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 25px;
      height: 0;
      margin-top: 1rem;
    }
    &__video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &__image:hover img {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 70vw;
      height: 80vh;
      border: 2px solid white;
    }
    &__images-container {
      position: relative;
      list-style: none;
      max-width: 100vw;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      padding: 0;
      margin: 0;
      gap: 4px;
      @media (min-width: 700px) {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(100px, auto);
      }
      & img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export default ProjectStyle;
