import LayoutStyle from "./Layout.styled";

const Layout = () => {
  return (
    <LayoutStyle className="body">
      <video autoPlay muted loop className="background">
        <source src="media/background.webm" type="video/mp4" />
      </video>
      {/* <img
        className="background-mobile"
        src="https://rpealnovxbgcljcptsrp.supabase.co/storage/v1/object/public/pictures/image00001.webp"
        alt="Girl with colse eyes"
      /> */}
      <img
        className="logo"
        src="media/logo-grande.svg"
        alt="logo Essenza"
        width={350}
      />
    </LayoutStyle>
  );
};

export default Layout;
