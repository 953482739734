import axios, { AxiosResponse } from "axios";
import { useCallback, useContext } from "react";
import { loadProjectsActionCreator } from "../store/actions/projectsActions/projectsActionCreators";
import ProjectsContext from "../store/contexts/ProjectsContext/ProjectsContext";
import Project from "../types/Project";

interface Response {
  projects: Project[];
}

const apiUrl = process.env.REACT_APP_API_URL;

const useApi = () => {
  const { dispatch } = useContext(ProjectsContext);

  const loadProjects = useCallback(async () => {
    try {
      const {
        data: { projects },
      }: AxiosResponse<Response> = await axios.get(`${apiUrl}/getProjects`);
      dispatch(loadProjectsActionCreator(await projects));
    } catch {
      console.log(apiUrl);
    }
  }, [dispatch]);

  const loadProjectsByName = useCallback(
    async (projectName: string) => {
      try {
        const {
          data: { projects },
        }: AxiosResponse<Response> = await axios.get(`${apiUrl}/getProjects`);

        const project = await projects.filter(
          ({ nombre }) => nombre === projectName?.split("-").join(" ")
        );

        dispatch(loadProjectsActionCreator(project));
      } catch {
        console.log("error");
      }
    },
    [dispatch]
  );
  return { loadProjects, loadProjectsByName };
};

export default useApi;
