import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import ProjectsContext from "../../store/contexts/ProjectsContext/ProjectsContext";
import addSpaces from "../../utils/addSpaces";
import credits from "../../utils/credits";
import ProjectStyle from "./Project.styled";

const Project = (): JSX.Element => {
  let { projectName } = useParams();
  projectName = typeof projectName === "string" ? projectName : "";

  const { loadProjectsByName } = useApi();

  useEffect(() => {
    loadProjectsByName(projectName as string);
  }, [loadProjectsByName, projectName]);

  const { projects } = useContext(ProjectsContext);
  const [project] = projects;

  return project ? (
    <ProjectStyle className="project">
      <h1 className="project__title">{project.cliente}</h1>
      <span className="project__name">{`"${project.nombre}"`}</span>
      <div className="project__front">
        <img
          className="project__front-image"
          src={project.frontBackup}
          alt={project.nombre}
        />
      </div>
      <p className="project__description">{project.descripcion}</p>
      <ul className="project__credits">
        <>
          <li className="project__credit">Essenza: {project.essenza}</li>
          {credits.map(
            (property) =>
              project[property] && (
                <li>
                  {addSpaces(
                    `${property[0].toUpperCase()}${property.substring(1)}`
                  )}
                  : {project[property]}
                </li>
              )
          )}
        </>
      </ul>
      {project.videos?.map((video) => (
        <div className="project__video-container">
          <iframe
            className="project__video"
            title="vimeo-player"
            src={video}
            width="1080"
            height="720"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      ))}
      <div className="project__images-container">
        {project.fotosBackups?.map((image) => (
          <div className="project__image">
            <img src={image} width={300} height={300} alt={project.cliente} />
          </div>
        ))}
      </div>
    </ProjectStyle>
  ) : (
    <span>Cargando</span>
  );
};

export default Project;
