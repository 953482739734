import { useReducer } from "react";
import { ProjectsSrtucture } from "../../../types/ProjectContextStructure";
import projectsReducer from "../../reducers/projectsReducer/projectsReducer";
import ProjectsContext from "./ProjectsContext";

interface SongsContextProviderProps {
  children: JSX.Element | JSX.Element[];
  initialProjectState?: ProjectsSrtucture;
}

const SongsContextProvider = ({
  children,
  initialProjectState = { projects: [] },
}: SongsContextProviderProps): JSX.Element => {
  const [projectsState, dispatch] = useReducer(
    projectsReducer,
    initialProjectState
  );

  return (
    <ProjectsContext.Provider value={{ ...projectsState, dispatch }}>
      {children}
    </ProjectsContext.Provider>
  );
};

export default SongsContextProvider;
