import styled from "styled-components";

const AboutStyle = styled.section`
  display: flex;
  flex-direction: column;
  width: 100vw;
  gap: 40px;
  .about {
    &__text {
      width: 80%;
      @media (min-width: 700px) {
        width: 60%;
      }
      margin: 0 auto;
      p {
        text-align: center;
        color: white;
        font-size: 0.8rem;
        line-height: 2;
        @media (min-width: 700px) {
          font-size: 1.2rem;
        }
      }
    }
    &__title {
      display: none;
      @media (min-width: 700px) {
        display: flex;
      }
      width: 100vw;
      justify-content: center;
      padding: 50px;
      & h1 {
        display: none;
      }
    }
  }
`;

export default AboutStyle;
