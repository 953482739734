import AboutStyle from "./About.styled";

const About = (): JSX.Element => {
  return (
    <AboutStyle className="about">
      <div className="about__title">
        <h1>Essenza</h1>
        <img width={300} src="../media/logo-grande.svg" alt="Essenza logo" />
      </div>
      <div className="about__text">
        <p>
          Somos Essenza, una compañía creativa de creación de contenido que nace
          en Barcelona con el fin de crear, mediante soluciones visuales,
          universos y personalidades que permitan a marcas, agencias, artistas e
          instituciones comunicar, partiendo siempre desde una “esencia” que
          tratamos de buscar, encontrar y establecer desde un principio en cada
          proyecto de manera que el público objetivo pueda identificarse.
        </p>
        <p>
          Nuestra prioridad siempre es plasmar nuestra visión para cumplir los
          objetivos de comunicación de nuestros clientes.
        </p>
        <p>
          Creemos en la cultura de colaboración entre los especialistas y
          profesionales del sector de la comunicación para desarrollar proyectos
          cada vez más profesionales.
        </p>
        <p>
          Lo cual nos permite mantenernos siempre en un estado de aprendizaje
          continuo para poder adaptarnos a los cambios del mercado y de las
          audiencias y no quedarnos nunca atrás frente a las nuevas tendencias y
          la frenética evolución del mundo.
        </p>
        <p>
          Dentro del caos social en el que vivimos, tratamos de encontrar un
          orden, un patrón, una “esencia” a partir de la cual tomar decisiones
          y, por ende, soluciones.
        </p>
      </div>
    </AboutStyle>
  );
};

export default About;
