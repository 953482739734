import {
  LoadProjectsAction,
  ProjectAction,
} from "../../actions/projectsActions/types/actionsProjects";
import { ProjectsSrtucture } from "../../../types/ProjectContextStructure";

const projectsReducer = (
  previewProjectsState: ProjectsSrtucture,
  action: ProjectAction
) => {
  let newProjectsState: ProjectsSrtucture;

  if ((action.type = "loadProjects")) {
    newProjectsState = {
      ...previewProjectsState,
      projects: (action as LoadProjectsAction).payload,
    };
  } else {
    newProjectsState = { ...previewProjectsState };
  }

  return newProjectsState;
};

export default projectsReducer;
