import styled from "styled-components";

const LayoutStyle = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  margin: auto;
  @media (max-width: 700px) {
    background-image: url("https://rpealnovxbgcljcptsrp.supabase.co/storage/v1/object/public/pictures/image00001.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .logo {
    @media (min-width: 1100px) {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    display: none;
  }
  .background {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 700px) {
    .background {
      display: none;
    }
  }
`;

export default LayoutStyle;
