import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import Work from "./components/Work/Work";
import Project from "./components/Projecct/Project";
import Contact from "./components/Contact/Contact";
import ProjectsContextProvider from "./store/contexts/ProjectsContext/ProjectsContextProvider";
import Layout from "./components/Layout/Layout";
import About from "./components/About/About";

const container = document.getElementById("root")!;
const root = createRoot(container);

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <Layout />,
        errorElement: <h1>Error</h1>,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "work",
        element: <Work />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "work/:projectName",
        element: <Project />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <ProjectsContextProvider>
      <RouterProvider router={router} />
    </ProjectsContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
