import Project from "../types/Project";

const credits: Array<keyof Project> = [
  "categoria",
  "directedBy",
  "producedBy",
  "dop",
  "primerAD",
  "segundoAD",
  "artDirection",
  "gaffer",
  "primerAC",
  "segundoAC",
  "styling",
  "makeUp",
  "artAssistant",
  "productionAssistant",
  "talents",
  "editor",
  "colorist",
  "vfx",
  "focusPuller",
  "cameraAssistant",
  "cast",
  "locations",
  "music",
  "color",
  "starring",
  "makeup",
];

export default credits;
