import styled from "styled-components";

const HeaderStyle = styled.header`
  display: flex;
  justify-content: space-between;
  @media (min-width: 700px) {
  }
  .header {
    display: flex;
    position: relative;
    flex-direction: column;
    max-width: 100vw;
    max-height: 100vh;
    @media (min-width: 1100px) {
      height: 60px;
      margin: 50px 0;
      flex: 1;
    }
    &__logo-container {
      @media (min-width: 1100px) {
        display: none;
      }
      margin: 45px;
      @media (min-width: 1100px) {
        margin: 45px 0 45px 10rem;
      }
      z-index: 10;
      align-self: center;
      justify-self: center;
    }
    &__burger {
      margin: 50px;
      z-index: 2;
      align-self: flex-end;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 40px;
      border-radius: 3px;
      width: 40px;
      background-color: white;
      border: 0;
      gap: 7px;
      padding: 6px;
      @media (min-width: 1100px) {
        display: none;
      }
      & > div {
        background-color: black;
        border-radius: 5px;
        height: 3px;
        width: 100%;
        transition: all 0.5s;
        transform-origin: left;
      }
      &--close div:first-child {
        transform: rotate(45deg);
      }
      &--close div:nth-child(2) {
        opacity: 0;
      }
      &--close div:last-child {
        transform: rotate(-45deg);
      }
    }
    &__nav {
      position: absolute;
      top: 0;
      transition: opacity 0.5s ease-in;
      height: 100vh;
      width: 50vw;
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-top: 120px;
      padding-right: 50px;
      gap: 2rem;
      background-color: black;
      display: none;
      z-index: 1;
      @media (min-width: 1100px) {
        display: flex;
        position: relative;
        flex-direction: row;
        align-content: center;
        opacity: 1;
        justify-content: space-evenly;
        height: 60px;
        width: 100%;
        padding: 0;
        background-color: transparent;
      }
      &--visible {
        @media (min-width: 1100px) {
          background-color: transparent;
        }
        background-color: white;
        opacity: 1;
        display: inline-flex;
      }
      & a {
        transition: all 0.5s;
        transform-origin: left;
        align-self: flex-end;
        font-size: 1.1rem;
        font-weight: 400;
        text-decoration: none;
        color: black;
        @media (min-width: 1100px) {
          color: white;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

export default HeaderStyle;
