import { useState } from "react";
import HeaderStyle from "./Header.styled";

const Header = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <HeaderStyle>
      <a className="header__logo-container" href="/">
        <img
          className="header__logo"
          width={60}
          src={"../media/logo.svg"}
          alt="essenza"
        />
      </a>
      <div className="header">
        <button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className={`header__burger ${isOpen && "header__burger--close"}`}
        >
          <div></div>
          <div></div>
          <div></div>
        </button>
        <div className={`header__nav ${isOpen && "header__nav--visible"}`}>
          <a href="/work">WORK</a>
          <a href="/contact">CONTACT</a>
          <a href="/about">ABOUT</a>
        </div>
      </div>
    </HeaderStyle>
  );
};
export default Header;
