import styled from "styled-components";

const WorkStyle = styled.section`
  .work {
    &__list {
      list-style: none;
      max-width: 100vw;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      padding: 0;
      margin: 0;
      @media (min-width: 700px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      & img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    &__title {
      font-size: 1rem;
    }
    &__item {
      position: relative;
      max-width: 100vw;
      align-content: center;
      color: black;
    }
    &__item:hover div {
      visibility: visible;
    }
    &__information {
      cursor: pointer;
      font-weight: 300;
      font-size: 1.3rem;
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #ffffffde;
      visibility: hidden;
      color: black;
    }
  }
`;

export default WorkStyle;
