import ContactStyle from "./Contact.styled";

const Contact = (): JSX.Element => {
  return (
    <ContactStyle className="contact">
      <a href="mailto:essenza.film@gmail.com" className="contact__email">
        essenza.film@gmail.com
      </a>
      <div className="contact__social">
        <a href="https://www.instagram.com/essenza_______/?hl=en">
          <img src="../media/instagram.svg" alt="" />
        </a>
        <a href="https://www.linkedin.com/in/essenza-865049176/">
          <img src="../media/linkedin.svg" alt="" />
        </a>
      </div>
      <a className="contact__mobile" href="tel://+34690830285">
        +34 690 830 285
      </a>
    </ContactStyle>
  );
};

export default Contact;
